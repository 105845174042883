<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/nav_logo.png')"
              max-height="200px"
              max-width="200px"
              alt="logo"
              contain
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text v-if="!loginLoading">
          <p class="text-2xl font-weight-semibold text--success mb-2 mt-0 text-center">
            Welcome back! 👋🏻
          </p>
          <div v-if="!user.isLoggedin">
            <p class="mb-2 text-center">
              Login to access your account.
            </p>
            <div class="text-center">
              <v-badge
                color="warning"
                class="mb-2 mr-0"
                overlap
                content="Beta"
              ></v-badge>
            </div>
          </div>
        </v-card-text>

        <!-- login form -->
        <v-card-text
          v-if="loginLoading"
          class="fadeOut text-center d-all justify-center mx-auto my-auto"
        >
          <v-progress-circular
            :size="50"
            color="success"
            indeterminate
            class="d-all text-center mx-auto my-auto"
          ></v-progress-circular>
        </v-card-text>
        <v-card-text>
          <v-snackbar
            v-model="snackbarShow"
            :color="snackbarType"
            :absolute="true"
            :top="true"
            :timeout="snackbarTimeout"
          >
            <v-layout
              align-center
              pr-4
            >
              <v-icon
                class="pr-3"
                dark
                large
              >
                {{ snackbarIcon }}
              </v-icon>
              <v-layout column>
                <div>{{ snackbarText }}</div>
              </v-layout>
            </v-layout>
          </v-snackbar>
          <v-form
            v-if="!loginLoading && !user.isLoggedin"
            id="login-form"
            @submit.prevent="login"
          >
            <v-text-field
              v-model="user.email"
              outlined
              color="success"
              label="Email"
              type="email"
              name="email"
              placeholder="Email"
              class="email-input email-extra mb-3"
              value
              hide-details
              required
            ></v-text-field>
            <v-text-field
              v-model="user.password"
              outlined
              color="success"
              label="Password"
              type="password"
              name="password"
              placeholder="Password"
              class="password-input email-extra mb-3"
              value
              hide-details
              required
            ></v-text-field>

            <v-btn
              type="submit"
              form="login-form"
              block
              color="success"
              class="mt-6"
              :loading="loginLoading"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>
        <!-- create new account  -->
        <v-card-text
          class="text-center mb-2 mt-1"
        >
          <a
            class="float-left"
            href="/join"
          >
            Create an account.
          </a>

          <a
            class="float-right"
            href="/password-reset"
          >
            Forgot your password?
          </a>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAccountRemoveOutline,
  mdiAccountCheck,
} from '@mdi/js'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  setup() {
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      socialLink,
      loginLoading: false,
      snackbarText: '',
      snackbarType: 'success',
      snackbarTimeout: 5000,
      snackbarShow: false,
      snackbarIcon: '',

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data() {
    return {
      user: {},
    }
  },
  mounted() {
    if (this.$store.state.user?.isLoggedin) {
      this.user = this.$store.state.user
      if (this.leagueCode) {
        this.$router.push('/leagues')
      } else {
        this.$router.push('/dashboard')
      }
    } else {
      this.user = {
        displayName: '',
        email: '',
        password: '',
        isLoggedin: false,
        photoURL: require('@/assets/images/avatars/default.png'),
      }
    }
  },
  methods: {
    login() {
      this.loginLoading = true
      firebase.auth()
        .signInWithEmailAndPassword(this.user.email, this.user.password).then(user => {
          this.user.password = '' // clear password
          this.user = user
          this.user.isLoggedin = true
          this.$store
            .dispatch('login', this.user)
            .then(() => {
              this.snackbarShow = true
              this.snackbarType = 'success'
              this.snackbarText = 'Login Successful'
              this.snackbarIcon = mdiAccountCheck
            })
            .catch(err => {
              console.log(err)
              this.loginLoading = false
              this.snackbarShow = true
              this.snackbarIcon = mdiAccountRemoveOutline
              this.snackbarType = 'error'
              this.snackbarText = err.message
            })
        })
        .catch(err => {
          console.log(err)
          this.loginLoading = false
          this.snackbarShow = true
          this.snackbarIcon = mdiAccountRemoveOutline
          this.snackbarType = 'error'
          this.snackbarText = err.message
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.text-danger {
  color: #EF5350;
}
</style>
